import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footer'>
      <h3>made by Stefan</h3>
      <p>Contact me</p>
    </div>
  )
}
